export const GroupMemberPermissionLevel = {
    10: {
        label: '成员',
        color: 'blue',
    },
    20: {
        label: '管理员',
        color: 'green',
    },
    30: {
        label: '拥有者',
        color: 'orange'
    },
}