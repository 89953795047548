import request from "../axios";

export function createGroupApi(name, desc) {
    return request({
        url: "/group/create",
        method: 'POST',
        data: {
            name: name,
            desc: desc
        }
    })
}

export function queryGroupApi(id) {
    return request({
        url: "/group/query",
        method: 'GET',
        params: {
            id: id
        }
    })
}

export function batchQueryGroupApi(name) {
    return request({
        url: "/group/query/batch",
        method: 'GET',
        params: {
            name: name
        }
    })
}

export function deleteGroupApi(id) {
    return request({
        url: "/group/delete",
        method: 'DELETE',
        params: {
            id: id
        }
    })
}