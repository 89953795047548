import request from "../axios";

export function batchQueryGroupMemberApi(groupId) {
    return request({
        url: "/group/member/query/batch",
        method: 'GET',
        params: {
            group_id: groupId
        }
    })
}

export function createGroupMemberApi(groupId, username) {
    return request({
        url: "/group/member/create",
        method: 'POST',
        data: {
            groupId: groupId,
            username: username
        }
    })
}

export function deleteGroupMemberApi(id) {
    return request({
        url: "/group/member/delete",
        method: 'DELETE',
        params: {
            id: id
        }
    })
}