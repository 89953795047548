import request from "../axios";

export function loginAuthApi(username, password, captcha, remember) {
    return request({
        url: "/auth/login",
        method: 'POST',
        data: {
            username: username,
            password: password,
            captcha: captcha,
            remember: remember
        }
    })
}

export function logoutAuthApi() {
    return request({
        url: "/auth/logout",
        method: 'DELETE'
    })
}

export function registerAuthApi(username, password, nickname) {
    return request({
        url: "/auth/register",
        method: 'POST',
        data: {
            username: username,
            password: password,
            nickname: nickname
        }
    })
}